define("ember-svg-jar/inlined/survey_builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"112.5\" cy=\"112.5\" fill=\"#5d9cec\" r=\"112.5\"/><g fill=\"#fff\"><path d=\"M112.6 91.5l55.7 55.8c6.1 6.1 6.1 16 0 22.1s-16 6.1-22.1 0L88.8 112m68.5 52.2c3.3 0 5.9-2.7 5.9-5.9 0-3.3-2.6-5.9-5.9-5.9s-5.9 2.7-5.9 5.9 2.6 5.9 5.9 5.9zm-86.5-46c-3.9-1.7-7.5-4-10.7-7.2-9.1-9.1-12.2-21.8-9.3-33.4.5-2.2 3.3-2.9 4.9-1.3l20.2 20.2 16.6-2.8 2.8-16.6-20.2-20.2c-1.6-1.6-.8-4.3 1.4-4.9 11.5-2.8 24.3.3 33.3 9.3 7 7 12.9 16.9 9.2 36.7l-19.1 8.6c-2-.5-2.8 11.6-4.9 11.6z\"/><path d=\"M105.3 96.6l5-34.8c13.9 13.9 14.3 36.5.4 50.4s-35.6 13.8-49.5-.1z\"/></g>",
    "attrs": {
      "viewBox": "0 0 225 225",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});