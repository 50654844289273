define("ember-composable-helpers/helpers/join", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function join(_ref) {
    let [separator, array] = _ref;
    if (!array) {
      array = [];
    }
    if ((0, _array.isArray)(separator)) {
      array = separator;
      separator = ',';
    }
    return array.join(separator);
  }
  var _default = (0, _helper.helper)(join);
  _exports.default = _default;
});