define("liquid-fire/templates/components/liquid-bind", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.containerless}}
    {{~#liquid-versions
      value=this.value
      use=this.use
      rules=this.rules
      matchContext=this.forwardMatchContext
      versionEquality=this.versionEquality
      renderWhenFalse=true
      class=this.class as |version|
    ~}}
      {{~#if (has-block)}}
        {{~yield version~}}
      {{else}}
        {{~version~}}
      {{/if~}}
    {{/liquid-versions~}}
  {{else}}
    {{~#liquid-container
      id=this.containerId
      class=this.class
      growDuration=this.growDuration
      growPixelsPerSecond=this.growPixelsPerSecond
      growEasing=this.growEasing
      shrinkDelay=this.shrinkDelay
      growDelay=this.growDelay
      enableGrowth=this.enableGrowth as |container|
    ~}}
      {{~#liquid-versions
        value=this.value
        notify=container
        use=this.use
        rules=this.rules
        matchContext=this.forwardMatchContext
        versionEquality=this.versionEquality
        renderWhenFalse=true as |version| 
      ~}}
        {{~#if (has-block)}}
          {{~yield version~}}
        {{else}}
          {{~version~}}
        {{/if~}}
      {{/liquid-versions~}}
    {{/liquid-container~}}
  {{/if}}
  */
  {
    "id": "DJr96mpc",
    "block": "[[[41,[30,0,[\"containerless\"]],[[[6,[39,1],null,[[\"value\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\",\"class\"],[[30,0,[\"value\"]],[30,0,[\"use\"]],[30,0,[\"rules\"]],[30,0,[\"forwardMatchContext\"]],[30,0,[\"versionEquality\"]],true,[30,0,[\"class\"]]]],[[\"default\"],[[[[41,[48,[30,4]],[[[18,4,[[30,1]]]],[]],[[[1,[30,1]]],[]]]],[1]]]]]],[]],[[[6,[39,4],null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[30,0,[\"containerId\"]],[30,0,[\"class\"]],[30,0,[\"growDuration\"]],[30,0,[\"growPixelsPerSecond\"]],[30,0,[\"growEasing\"]],[30,0,[\"shrinkDelay\"]],[30,0,[\"growDelay\"]],[30,0,[\"enableGrowth\"]]]],[[\"default\"],[[[[6,[39,1],null,[[\"value\",\"notify\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\"],[[30,0,[\"value\"]],[30,2],[30,0,[\"use\"]],[30,0,[\"rules\"]],[30,0,[\"forwardMatchContext\"]],[30,0,[\"versionEquality\"]],true]],[[\"default\"],[[[[41,[48,[30,4]],[[[18,4,[[30,3]]]],[]],[[[1,[30,3]]],[]]]],[3]]]]]],[2]]]]]],[]]]],[\"version\",\"container\",\"version\",\"&default\"],false,[\"if\",\"liquid-versions\",\"has-block\",\"yield\",\"liquid-container\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-bind.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});