define("ember-screen/window/null", ["exports", "css-mediaquery"], function (_exports, _cssMediaquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MOST_COMMON_SCREEN_SIZE = {
    width: 1366,
    height: 768,
    type: 'screen'
  };
  class _default {
    constructor() {
      this.listeners = [];
      this.stubbedMediaFeatures = MOST_COMMON_SCREEN_SIZE;
    }
    onSizeUpdate(listener) {
      // Immediately call the listener to set initial size
      listener(this.dimensions);
      this.listeners.push(listener);
    }
    get dimensions() {
      return {
        width: this.stubbedMediaFeatures.width,
        height: this.stubbedMediaFeatures.height
      };
    }
    stubMediaFeatures(features) {
      this.stubbedMediaFeatures = features;
      this.listeners.forEach(l => l(this.dimensions));
    }
    teardown() {}
    matchesMediaQuery(query) {
      return (0, _cssMediaquery.matchQuery)(query, this.stubbedMediaFeatures);
    }
  }
  _exports.default = _default;
});