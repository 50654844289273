define("ember-svg-jar/inlined/empty-tier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M36.9 2.6h-4.1C32.2 1.4 30.9.5 29.4.5H12.2c-1.5 0-2.8.8-3.4 2.1H4.7C1.6 2.6-.2 4.8.4 7.9l2.1 10.9c.5 2.4 2.2 4 4.7 4h3.3c1.6 2.3 3.9 4 6.7 4.8-.3 2.1-.9 4.7-1.7 6.5l-2 .1c-1.9 0-2.8.8-3 2.6-.1.6-.3.7-.8.8-1 .2-1.5.9-1.4 1.9.1 1.1.8 1.4 1.8 1.4h21.4c.8 0 1.5-.1 1.6-1.2 0-1-.1-2-1.3-2.1-.8-.1-.9-.4-1-1.1-.1-1.6-.9-2.3-2.4-2.3-.8 0-1.7-.1-2.5-.1-.9-1.7-1.4-4.3-1.7-6.4 2.9-.8 5.3-2.6 7-5h3.3c2.5-.1 4.2-1.6 4.7-4l2.1-10.9c.5-2.9-1.3-5.2-4.4-5.2zm-28.1 17c-1.5 0-2.4-.6-2.7-2.1L4.3 9.2c-.5-2.5.3-3.3 2.8-3.3h1.2v9.8a16 16 0 00.5 3.9zM37.3 9.2l-1.8 8.3c-.3 1.5-1.2 2.1-2.7 2.1h-.1c.4-1.2.6-2.5.6-3.8V6h1.2c2.5-.1 3.3.7 2.8 3.2z\" fill=\"#e6e9ed\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 41.6 41.6"
    }
  };
  _exports.default = _default;
});