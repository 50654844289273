define("ember-svg-jar/inlined/non_teaching_staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M80 40c0 22.1-17.9 40-40 40S0 62.1 0 40 17.9 0 40 0s40 17.9 40 40z\" fill=\"#4fc1e9\"/><path d=\"M46.4 44.1c-1-1.7-.4-4 .3-5.6 1.8-1.7 3.1-3.8 3.8-6.2.1.2.4.4.7.4.8 0 1.4-1.5 1.4-3.3s-.6-3.3-1.4-3.3c-.4-6.7-3.2-12-11.1-12-8.1 0-10.8 5.2-11.1 12-.8 0-1.4 1.5-1.4 3.3s.6 3.3 1.4 3.3c.3 0 .5-.2.7-.4.7 2.4 2 4.5 3.8 6.2.7 1.6 1.3 3.9.3 5.6 0 0-17.5 6.4-17.5 13.7h47.8c-.2-7.3-17.7-13.7-17.7-13.7z\" fill=\"#fff\"/><path d=\"M54.9 66.7c-.8 0-1.6-.3-2.2-.9l-9.1-9.1c-.5-.5-.9-1.4-.9-2.2v-8.8c0-1.7 1.4-3.1 3.1-3.1h8.8c.7 0 1.6.4 2.2.9l9.1 9.1c.6.6.9 1.3.9 2.2 0 .8-.3 1.6-.9 2.2l-8.8 8.8c-.6.6-1.3.9-2.2.9z\" fill=\"#fff\"/><g fill=\"#4fc1e9\"><path d=\"M54.6 43.7c.5 0 1.1.3 1.5.6l9.1 9.1c.8.8.8 2.1 0 2.9l-8.8 8.8c-.4.4-.9.6-1.5.6s-1.1-.2-1.5-.6l-9-9.1c-.3-.3-.6-1-.6-1.5v-8.8c0-1.1.9-2.1 2.1-2.1h8.7m0-1.9h-8.8c-2.2 0-4.1 1.8-4.1 4.1v8.8c0 1 .5 2.2 1.2 2.9l9.1 9.1c.8.8 1.8 1.2 2.9 1.2s2.1-.4 2.9-1.2l8.8-8.8c.8-.8 1.2-1.8 1.2-2.9s-.4-2.1-1.2-2.9l-9.1-9.1c-.7-.8-1.9-1.2-2.9-1.2z\"/><circle cx=\"48.6\" cy=\"48.5\" r=\"2.1\"/></g>",
    "attrs": {
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});