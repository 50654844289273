define("ember-svg-jar/inlined/unsure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"svg-face-mouth\" d=\"M22.9 57.5c-1.6 0-2.9-1.3-2.9-2.9 0-1.1.7-2.2 1.7-2.7 19.4-9 34.8-10.7 53.1-5.8 1.6.5 2.5 2.1 2 3.6-.4 1.5-2 2.4-3.5 2-16.9-4.6-31.1-3-49.1 5.4-.4.4-.8.4-1.3.4z\"/><path d=\"M71.4 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8zM25 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 80"
    }
  };
  _exports.default = _default;
});