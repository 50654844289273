define("ember-svg-jar/inlined/upload_in_progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M38.07 48.02h-32c-3.31 0-6-2.69-6-6v-36c0-3.31 2.69-6 6-6h28l10 10v32c0 3.31-2.68 6-6 6z\" fill=\"#e6e9ed\"/><circle cx=\"21.99\" cy=\"25.16\" fill=\"#37bc9b\" r=\"12.5\"/><path d=\"M16.21 25.49a.652.652 0 010-.93l5.31-5.32c.26-.26.67-.26.93 0l5.31 5.31c.26.26.26.67 0 .93l-.61.61c-.26.26-.68.25-.94-.01l-3.14-3.29v7.86c0 .36-.29.66-.66.66h-.88a.66.66 0 01-.66-.66v-7.86l-3.14 3.29c-.25.27-.68.27-.94.01z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 44.07 48.02",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});