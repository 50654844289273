define("ember-svg-jar/inlined/hamburger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"currentColor\" stroke-width=\"2\" fill=\"none\"><path d=\"M0 1h17M0 6h17M0 11h18\"/></g>",
    "attrs": {
      "width": "17",
      "height": "12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});