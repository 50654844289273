define("ember-svg-jar/inlined/marvel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M45.5 2.1c32.8-8.8 66.4 10.7 75.2 43.4 8.8 32.8-10.7 66.4-43.4 75.2-32.8 8.8-66.4-10.7-75.2-43.4-8.8-32.7 10.7-66.4 43.4-75.2z\" fill=\"#fbd433\"/><path d=\"M41.9 27.9c4.3 0 7.8 4.9 7.8 11s-3.5 11-7.8 11-7.8-4.9-7.8-11 3.5-11 7.8-11zM28.5 67.1c16.7-.5 51 .3 65.8 0 0 39.7-65.8 43.6-65.8 0zM81 27.9c4.3 0 7.8 4.9 7.8 11s-3.5 11-7.8 11-7.8-4.9-7.8-11 3.5-11 7.8-11z\" fill=\"#141518\"/><path d=\"M28.5 67.1c16.7-1.5 51-.7 65.8 0 .2 14.1-65.5 14-65.8 0z\" fill=\"#fff\"/><path d=\"M38.6 90.9c11.1 9.6 31.8 10 44.3 0-6.8-7.7-14.2-7.9-22.4-1.9-8.5-6.7-14.1-6.9-21.9 1.9z\" fill=\"red\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 122.9 122.9"
    }
  };
  _exports.default = _default;
});