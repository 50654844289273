define("ember-intercom-io/components/intercom-io", ["exports", "@ember/service", "@ember/component", "@ember/object"], function (_exports, _service, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    intercom: (0, _service.inject)(),
    didInsertElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'intercom').start();
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'intercom').stop();
    }
  });
  _exports.default = _default;
});