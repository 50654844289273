define("ember-svg-jar/inlined/pulse_logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M161.9 86.7l141.9 195.8H20z\" fill=\"#42b598\"/><path d=\"M330.8 21.5L520 282.6H141.6z\" fill=\"#fece56\"/><path d=\"M141.6 282.6l81.1-111.9 81.1 111.9z\" fill=\"#bf647f\"/>",
    "attrs": {
      "viewBox": "0 0 540 302.7",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});