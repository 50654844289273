define("ember-svg-jar/inlined/curious", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M165.8 251C160.3 289.8 5.7 287.3.2 251v210.2c0 68.1 165.6 69.8 165.6-.5V251z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#ffd276\"/><path d=\"M68.9 284.5C34.1 282.3 2.6 267 .2 251v210.2c0 30.2 32.5 47.3 68.7 50.9V284.5z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#ffd999\"/><path d=\"M49.7 219.8l64.8-.3c.2 0 .4 0 .7.1 14.4 2.3 26.7 6.1 35.3 10.8 9.8 5.4 15.6 12.5 15.6 20.8 0 10.8-10.1 19.7-26.4 25.6-14.6 5.2-34.6 8.4-56.6 8.4s-42-3.2-56.5-8.4c-16.5-6-26.6-15-26.6-25.7 0-8.1 5.5-15.1 14.9-20.4 8.3-4.7 20-8.5 33.8-10.8.3-.1.7-.2 1-.1z\" fill=\"#ffd276\"/><path d=\"M49.4 225.9l65.1-.3c27.4 4.3 46.5 14.1 46.5 25.6 0 15.4-34.9 27.9-78 27.9s-78-12.6-78-28c0-11.2 18.2-20.8 44.4-25.2z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#ffeac8\"/><g><path d=\"M92 259.9L74.3 253C5.7 187 92.5 125.3 79.7 98.3c48.4 34.5 13.6 158.6 12.3 161.6z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#ffc928\"/><path d=\"M92 259.9L74.3 253c-5.4-5.2-9.8-10.3-13.3-15.4 8.2-19.3 21.5-35.2 16.9-44.9 14 10 18.3 30.5 18.3 51.5-2.2 9.2-3.9 15-4.2 15.7z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#fff073\"/><path d=\"M61.5 0c70.6 60.6 112.5 144.5 65.4 215.2-11.7 17.6-26.9 26.2-35 44.6 1.3-3 36.1-127-12.3-161.5 12.8 27-73.9 88.7-5.4 154.6C-55.7 159.6 84.3 47.9 61.5 0z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#f77d02\"/><path d=\"M77 245c0-3.8 3.2-6.8 6.9-6.8s6.7 3.1 6.8 6.8v16.3c0 3.8-3.2 6.8-6.9 6.8s-6.7-3.1-6.8-6.8V245z\" fill=\"#4d4d4d\"/></g>",
    "attrs": {
      "text-rendering": "geometricPrecision",
      "image-rendering": "optimizeQuality",
      "shape-rendering": "geometricPrecision",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 166 512.8"
    }
  };
  _exports.default = _default;
});