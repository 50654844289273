define("ember-svg-jar/inlined/staff_member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M80 40A40 40 0 1140 0a40 40 0 0140 40z\" fill=\"#4fc1e9\"/><path d=\"M46.41 44.11c-1-1.74-.38-4.05.29-5.62a13.63 13.63 0 003.79-6.18.86.86 0 00.67.43c.76 0 1.37-1.47 1.37-3.29s-.61-3.29-1.37-3.29c-.36-6.73-3.23-12-11.13-12-8.11 0-10.79 5.24-11.12 12-.75 0-1.37 1.47-1.37 3.29s.62 3.29 1.37 3.29a.89.89 0 00.68-.43 13.6 13.6 0 003.79 6.19c.68 1.57 1.32 3.88.29 5.62 0 0-17.53 6.36-17.53 13.7h47.8c0-7.34-17.53-13.71-17.53-13.71z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});