define("ember-composable-helpers/helpers/map-by", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function mapBy(_ref) {
    let [byPath, array] = _ref;
    if ((0, _utils.isEmpty)(byPath)) {
      return [];
    }
    if (!array) {
      array = [];
    }
    return array.map(item => item[byPath]);
  }
  var _default = (0, _helper.helper)(mapBy);
  _exports.default = _default;
});