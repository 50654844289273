define("ember-svg-jar/inlined/manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"40\" cy=\"39.99\" r=\"39.56\"/><circle cx=\"40\" cy=\"40\" fill=\"#ffd876\" r=\"40\"/><path d=\"M63.69 58c0 .29-.42.54-1 .54H17.67c-.52 0-1-.24-1-.54v-1.24c0-.3.43-.54 1-.54h45.07c.53 0 1 .24 1 .54z\" fill=\"#0e79c9\"/><path d=\"M18.28 28.2h43.43v28.02H18.28z\" fill=\"#fff\"/><path d=\"M62.74 23.56H43.61v-.72c0-.76-.43-1.38-.95-1.38h-4.9c-.52 0-1 .62-1 1.38v.72H17.67a1 1 0 00-1 1.08v2.48a1 1 0 001 1.08h45.07a1 1 0 001-1.08v-2.48a1 1 0 00-1-1.08z\" fill=\"#0e79c9\"/><g fill=\"#4fc1e9\"><path d=\"M24.45 39.52h3.83v11.5h-3.83zM31.26 42.21h3.83v8.81h-3.83zM38.08 44.17h3.83v6.85h-3.83zM44.89 33.4h3.84v17.61h-3.84zM51.71 38.66h3.84v12.35h-3.84z\"/></g>",
    "attrs": {
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});