define("ember-svg-jar/inlined/organisation_manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"39.95\" cy=\"40\" fill=\"#48cfad\" r=\"40\"/><g fill=\"#0c9573\"><path d=\"M39 31.11h2.32V39H39zM25.16 37.95h2.32v7.89h-2.32zM51.96 37.95h2.32v7.89h-2.32z\"/><path d=\"M26.32 37.95h26.8V40h-26.8z\"/></g><rect fill=\"#fff\" height=\"18\" rx=\"3\" width=\"18\" x=\"17.32\" y=\"43.91\"/><rect fill=\"#fff\" height=\"18\" rx=\"3\" width=\"18\" x=\"30.95\" y=\"13.11\"/><rect fill=\"#fff\" height=\"18\" rx=\"3\" width=\"18\" x=\"44.12\" y=\"43.91\"/>",
    "attrs": {
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});