define("ember-svg-jar/inlined/happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"svg-face-mouth\" d=\"M50 61.4c-11.1 0-21.8-3.8-30.4-10.9-1.2-1-1.4-2.9-.4-4.1 1-1.2 2.9-1.4 4.1-.4 15.5 12.8 37.9 12.8 53.4 0 1.2-1 3.1-.8 4.1.4s.8 3.1-.4 4.1c-8.5 7-19.3 10.9-30.4 10.9z\"/><path d=\"M12.4 16.8c-1.6 0-2.9-1.3-2.9-2.9 0-.4.1-.8.3-1.2 1.7-3.8 4.7-6.8 8.4-8.4 3.8-1.8 8.3-2 12.2-.5 4.3 1.6 7.8 4.8 9.7 9 .7 1.5 0 3.2-1.4 3.9s-3.2 0-3.9-1.4c-1.2-2.7-3.5-4.9-6.4-5.9-2.5-.9-5.3-.8-7.8.3s-4.5 3.1-5.6 5.6c-.4.8-1.4 1.5-2.6 1.5zM62.5 16.8c-1.6 0-2.9-1.3-2.9-2.9 0-.4.1-.8.3-1.2 1.7-3.8 4.7-6.8 8.4-8.4 3.8-1.8 8.3-2 12.2-.5 4.3 1.6 7.8 4.8 9.7 9 .7 1.5 0 3.2-1.4 3.9-1.5.7-3.2 0-3.9-1.4-1.3-2.7-3.5-4.9-6.4-5.9-2.5-.9-5.3-.8-7.8.3s-4.5 3.1-5.6 5.6c-.4.8-1.4 1.5-2.6 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 80"
    }
  };
  _exports.default = _default;
});