define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.3 1.2L9.7 8.5l-8 1.2c-1 .1-1.6 1-1.5 2 .1.4.2.7.5 1l5.8 5.6-1.4 8c-.2 1 .5 1.9 1.4 2 .4.1.8 0 1.1-.2l7.1-3.8L22 28c.9.4 1.9.1 2.4-.7.2-.3.2-.7.2-1.1l-1.4-8 5.8-5.6c.7-.7.7-1.8 0-2.5-.3-.3-.6-.5-1-.5l-8-1.2-3.6-7.2C16 .3 14.9 0 14.1.4c-.4.2-.7.5-.8.8z\" fill=\"#FECF55\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 29.6 28.5"
    }
  };
  _exports.default = _default;
});