define("ember-svg-jar/inlined/mtss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.mtss_svg_1{fill:#ec87c0}</style><circle id=\"Circle_3_\" class=\"mtss_svg_1\" cx=\"112.5\" cy=\"112.5\" r=\"112.5\"/><g id=\"Triangle\"><defs><circle id=\"Circle_00000067234565987685131890000004495101149990497426_\" cx=\"112.5\" cy=\"112.5\" r=\"112.5\"/></defs><clipPath id=\"Circle_00000101788888806276120980000017186915871332497047_\"><use xlink:href=\"#Circle_00000067234565987685131890000004495101149990497426_\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#Circle_00000101788888806276120980000017186915871332497047_)\"><defs><path id=\"SVGID_1_\" d=\"M112.5 15L0 210.3h225z\"/></defs><clipPath id=\"SVGID_00000065037998312840968410000006675349207443825838_\"><use xlink:href=\"#SVGID_1_\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#SVGID_00000065037998312840968410000006675349207443825838_)\" fill=\"#ec87c0\" d=\"M112.5 0L0 191.6h225z\"/><path clip-path=\"url(#SVGID_00000065037998312840968410000006675349207443825838_)\" fill=\"#f5c3df\" d=\"M0 75.7h225v75.7H0z\"/><path clip-path=\"url(#SVGID_00000065037998312840968410000006675349207443825838_)\" fill=\"#c3669d\" d=\"M0 151.3h225V225H0z\"/><path clip-path=\"url(#SVGID_00000065037998312840968410000006675349207443825838_)\" fill=\"#fff\" d=\"M0 0h225v75.7H0z\"/></g></g><path class=\"mtss_svg_1\" d=\"M112.5 15c53.8 0 97.5 43.7 97.5 97.5S166.3 210 112.5 210 15 166.3 15 112.5 58.7 15 112.5 15m0-15C50.4 0 0 50.4 0 112.5S50.4 225 112.5 225 225 174.6 225 112.5 174.6 0 112.5 0z\" id=\"Bevel\"/>",
    "attrs": {
      "version": "1.1",
      "id": "MTSS",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 225 225",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});