define("ember-svg-jar/inlined/survey-builder-new-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"112.5\" cy=\"112.5\" r=\"112.5\" fill=\"#5d9cec\"/><path d=\"M75.6 84.6h70.7c5.5 0 10-4.5 10-10V8.8C142.8 3.1 128 0 112.5 0 95.8 0 79.9 3.7 65.6 10.2v64.4c0 5.5 4.5 10 10 10z\" fill=\"#fff\"/><path d=\"M141.1 21.3c1.7 1.7 1.7 4.5 0 6.2l-35 35c-1.7 1.7-4.5 1.7-6.2 0L82.4 45c-1.7-1.7-1.7-4.5 0-6.2s4.5-1.7 6.2 0l14.3 14.4 32-31.9c1.7-1.7 4.5-1.7 6.2 0z\" fill=\"#5d9cec\"/><path d=\"M146.3 185.4H75.6c-5.5 0-10-4.5-10-10v-70.7c0-5.5 4.5-10 10-10h70.7c5.5 0 10 4.5 10 10v70.7c0 5.5-4.5 10-10 10z\" fill=\"#fff\"/><g><path d=\"M128.4 128.2l21.6 21.7-10.9-11 75.4-74c-5.2-11.2-12.2-21.3-20.6-30.2L135.3 97l-6.9 31.2z\" fill=\"#b4d0f5\"/><path d=\"M123.5 150.6l4.9-22.4-5 22.5c-.5 2.9 2.1 5.3 4.9 4.6l21.8-5.3-.1-.1-21.6 5.3c-2.8.7-5.4-1.8-4.9-4.6z\" fill=\"#5d9cec\"/><path d=\"M123.5 150.6c-.5 2.8 2.1 5.3 4.9 4.6l21.6-5.3-21.6-21.7-4.9 22.4z\" fill=\"#5d9cec\"/><path d=\"M139.1 138.9l10.9 11 27.4-6.6 46.7-45.2c-1.5-11.7-4.8-22.9-9.6-33.2l-75.4 74z\" fill=\"#b4d0f5\"/><path d=\"M139.1 138.9l10.9 11 27.4-6.6 46.7-45.2c-1.5-11.7-4.8-22.9-9.6-33.2l-75.4 74z\" opacity=\".2\" fill=\"#5d9cec\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 225 225"
    }
  };
  _exports.default = _default;
});