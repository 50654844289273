define("ember-svg-jar/inlined/uploaded_with_errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M38.07 48.02h-32c-3.31 0-6-2.69-6-6v-36c0-3.31 2.69-6 6-6h28l10 10v32c0 3.31-2.68 6-6 6z\" fill=\"#f3a100\"/><g fill=\"#fff\"><circle cx=\"44\" cy=\"47\" r=\"9\"/><path d=\"M9.05 21h11v7h-11zM24 21h11v7H24zM9.05 32.01h11v7h-11zM24 32.01h11v7H24z\"/></g><path d=\"M47.13 49.85h-7.5c-.58 0-.94-.63-.65-1.12l3.75-6.5a.75.75 0 011.3 0l3.75 6.5c.29.5-.07 1.12-.65 1.12zm-4.47-1.75c0 .4.32.72.72.72s.72-.32.72-.72-.32-.72-.72-.72-.72.32-.72.72zm.16-1.18c0 .1.09.18.19.18h.76c.1 0 .18-.08.19-.18l.12-2.12c.01-.11-.08-.2-.19-.2h-.99c-.11 0-.19.09-.19.2z\" fill=\"#f3a100\"/>",
    "attrs": {
      "viewBox": "0 0 53 56",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});