define("ember-svg-jar/inlined/partner_admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"39.95\" cy=\"40\" fill=\"#ac92ec\" r=\"40\"/><path d=\"M62.12 51.39a4.52 4.52 0 01-4.52 4.52H22.3a4.51 4.51 0 01-4.51-4.52V30.18a4.51 4.51 0 014.51-4.52h35.31a4.52 4.52 0 014.52 4.52z\" fill=\"#fff\"/><path d=\"M32.44 43.55a2.34 2.34 0 01.1-2 4.94 4.94 0 001.36-2.21.31.31 0 00.24.15c.27 0 .49-.52.49-1.18s-.22-1.18-.49-1.18c-.13-2.41-1.16-4.29-4-4.29s-3.86 1.87-4 4.29c-.27 0-.5.53-.5 1.18s.23 1.18.5 1.18a.32.32 0 00.24-.15 4.93 4.93 0 001.35 2.21 2.31 2.31 0 01.11 2s-6.28 2.28-6.28 4.91h17.16c0-2.63-6.28-4.91-6.28-4.91z\" fill=\"#5d9cec\"/><g fill=\"#0e79c9\"><path d=\"M41.52 32.83h8.85v1.44h-8.85zM52.24 32.83h5.18v1.44h-5.18zM41.52 40h15.91v.64H41.52zM41.52 41.95h15.91v.64H41.52zM41.52 43.91h15.91v.64H41.52zM41.52 45.86h15.91v.64H41.52zM41.52 47.81h11.44v.64H41.52zM49.34 27.49a.71.71 0 01-.71.71H31.29a.71.71 0 01-.72-.71v-3.66a.72.72 0 01.72-.72h17.33a.72.72 0 01.71.72z\"/><path d=\"M42.63 19.14a1 1 0 011 1V26a1 1 0 01-1 1h-5.34a1 1 0 01-1-1v-5.82a1 1 0 011-1z\"/></g>",
    "attrs": {
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});