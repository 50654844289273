define("ember-svg-jar/inlined/sad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"svg-face-mouth\" d=\"M25.7 58c-1.6 0-2.9-1.3-2.9-2.9 0-.5.2-1.1.4-1.5 5.2-8.4 15.5-13.4 27-13 10.1.3 19.5 5.3 24.5 13 .9 1.3.5 3.2-.8 4-1.3.9-3.2.5-4-.8-3.9-6-11.7-10.1-19.8-10.3-9.3-.3-17.9 3.8-21.9 10.3-.6.7-1.5 1.2-2.5 1.2z\"/><path d=\"M71.4 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8zM25 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 80"
    }
  };
  _exports.default = _default;
});