define("liquid-fire/templates/components/liquid-outlet", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LfGetOutletState as |outletState|>
    {{~#liquid-bind
      (lf-lock-model outletState this.outletName)
      containerId=this.containerId
      versionEquality=this.versionEquality
      matchContext=(hash outletName=this.outletName helperName="liquid-outlet")
      class=this.class
      use=this.use
      rules=this.rules
      containerless=this.containerless
      growDuration=this.growDuration
      growPixelsPerSecond=this.growPixelsPerSecond
      growEasing=this.growEasing
      shrinkDelay=this.shrinkDelay
      growDelay=this.growDelay
      enableGrowth=this.enableGrowth as |version|
    ~}}
      {{#-with-dynamic-vars outletState=version~}}
        {{outlet}}
      {{~/-with-dynamic-vars}}
    {{~/liquid-bind~}}
  </LfGetOutletState>
  */
  {
    "id": "z00RxX5l",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[6,[39,1],[[28,[37,2],[[30,1],[30,0,[\"outletName\"]]],null]],[[\"containerId\",\"versionEquality\",\"matchContext\",\"class\",\"use\",\"rules\",\"containerless\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[30,0,[\"containerId\"]],[30,0,[\"versionEquality\"]],[28,[37,3],null,[[\"outletName\",\"helperName\"],[[30,0,[\"outletName\"]],\"liquid-outlet\"]]],[30,0,[\"class\"]],[30,0,[\"use\"]],[30,0,[\"rules\"]],[30,0,[\"containerless\"]],[30,0,[\"growDuration\"]],[30,0,[\"growPixelsPerSecond\"]],[30,0,[\"growEasing\"]],[30,0,[\"shrinkDelay\"]],[30,0,[\"growDelay\"]],[30,0,[\"enableGrowth\"]]]],[[\"default\"],[[[[45,[[\"outletState\"],[[30,2]]],[[[46,[28,[37,6],null,null],null,null,null]],[]]]],[2]]]]]],[1]]]]]],[\"outletState\",\"version\"],false,[\"lf-get-outlet-state\",\"liquid-bind\",\"lf-lock-model\",\"hash\",\"-with-dynamic-vars\",\"component\",\"-outlet\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-outlet.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});