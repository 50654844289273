define("ember-screen/breakpoint", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = breakpoint;
  function breakpoint(mediaQuery) {
    return (0, _object.computed)('width', 'win', function () {
      return this.win.matchesMediaQuery(mediaQuery);
    });
  }
});