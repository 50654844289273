define("ember-svg-jar/inlined/message-tail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle stroke=\"#30a3fc\" cy=\"6.895\" cx=\"10.477\" stroke-width=\"0\" fill=\"currentColor\" r=\"24\"/><circle stroke=\"#fff\" cy=\".022\" cx=\"-10.438\" stroke-width=\"1.5\" fill=\"#fff\" r=\"29.625\"/>",
    "attrs": {
      "width": "40",
      "height": "31",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});