define("ember-svg-jar/inlined/horizontal-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.6 17.5H3.4c-.3 0-.5-.3-.4-.6 0-.2.2-.4.4-.4h13.1c.3 0 .5.3.4.6.1.2-.1.4-.3.4zM16.6 14.5H3.4c-.3 0-.5-.3-.4-.6 0-.2.2-.4.4-.4h13.1c.3 0 .5.3.4.6.1.2-.1.4-.3.4zM16.6 11.5H3.4c-.3 0-.5-.3-.4-.6 0-.2.2-.4.4-.4h13.1c.3 0 .5.3.4.6.1.2-.1.4-.3.4zM3.1 6.6L1.8 5.5h16.3l-1.2 1.1c-.2.2-.2.5 0 .7.2.2.5.2.8 0l2.2-2c.2-.2.2-.5 0-.7l-2.2-2c-.2-.2-.5-.2-.8 0-.2.2-.2.5 0 .7l1.2 1.1H1.8L3 3.3c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.8 0L0 4.6c0 .2-.1.5.1.7l2.2 2c.2.2.5.2.8 0 .2-.1.2-.4 0-.7 0 .1 0 .1 0 0z\"/>",
    "attrs": {
      "height": "21",
      "viewBox": "0 0 20 20",
      "width": "21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});