define("ember-svg-jar/inlined/neutral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M71.4 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8zM25 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8z\"/><path class=\"svg-face-mouth\" d=\"M25 50h46\" fill=\"none\" stroke=\"#000\" stroke-width=\"6\" stroke-linecap=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 80"
    }
  };
  _exports.default = _default;
});