define("ember-svg-jar/inlined/muted_survey_icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#da4453\"><path d=\"M15.65 82.43a2.53 2.53 0 00-.88 2.78 2.65 2.65 0 00.93 1.25 2.38 2.38 0 001.19.44h70.55a2.46 2.46 0 001.18-.44 2.33 2.33 0 00.9-1.25 2.61 2.61 0 000-1.54 2.29 2.29 0 00-.9-1.25 21.89 21.89 0 01-3.81-3.64A23 23 0 0182 74.47a22.47 22.47 0 01-1.76-4.83 22.19 22.19 0 01-.61-5.22v-15a26.55 26.55 0 00-1.51-9A28.44 28.44 0 0074 32.7a27.43 27.43 0 00-6.32-6 26.91 26.91 0 00-8-3.74V19.4a7.49 7.49 0 00-12.79-5.27 7.19 7.19 0 00-2.2 5.27V23a27 27 0 00-8 3.74 27.86 27.86 0 00-6.35 6 27.52 27.52 0 00-4.17 7.74 26.93 26.93 0 00-1.49 9v15a22.3 22.3 0 01-.61 5.22 22.68 22.68 0 01-1.76 4.83 22.47 22.47 0 01-2.83 4.32 22.62 22.62 0 01-3.83 3.58zm34-63a2.49 2.49 0 015 0V22h-4.96zM18.92 31.16a2.49 2.49 0 00-1.76-4.25h-5.32l7.42-11.13a2.61 2.61 0 00.39-1.25 2.2 2.2 0 00-.29-1.29 2.45 2.45 0 00-.93-1 2.48 2.48 0 00-1.27-.34h-10a2.49 2.49 0 00-1.76 4.27 2.4 2.4 0 001.76.73h5.32L5.11 28a2.58 2.58 0 00-.44 1.27A2.16 2.16 0 005 30.58a2.44 2.44 0 00.93 1 2.47 2.47 0 001.27.34h10a2.4 2.4 0 001.72-.76zM39.92 91.91a11.86 11.86 0 00.74 2.37 12.6 12.6 0 006.67 6.66 12.57 12.57 0 009.72 0 12.6 12.6 0 006.66-6.66 11.86 11.86 0 00.74-2.37zM103.93 27.65a2.4 2.4 0 00-1.76-.73h-10l12-16a2.61 2.61 0 00.24-2.59 2.76 2.76 0 00-1-1 2.37 2.37 0 00-1.29-.39h-15a2.5 2.5 0 00-2.54 2.54 2.4 2.4 0 00.73 1.76 2.45 2.45 0 001.81.73h10l-12 16a2.56 2.56 0 00-.51 1.29 2.27 2.27 0 00.27 1.34 2.23 2.23 0 00.9 1 2.56 2.56 0 001.34.37h15a2.49 2.49 0 001.76-4.25z\"/></g>",
    "attrs": {
      "viewBox": "0 0 108 108",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});