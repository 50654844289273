define("ember-svg-jar/inlined/upload_csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M38.07 48.02h-32c-3.31 0-6-2.69-6-6v-36c0-3.31 2.69-6 6-6h28l10 10v32c0 3.31-2.68 6-6 6z\" fill=\"#37bc9b\"/><circle cx=\"44\" cy=\"47\" fill=\"#fff\" r=\"9\"/><path d=\"M48.969 46.005v2h-10v-2z\" fill=\"#37bc9b\"/><path d=\"M42.97 42.01h2v10h-2z\" fill=\"#37bc9b\"/><g fill=\"#fff\"><path d=\"M9.05 21h11v7h-11zM24 21h11v7H24zM9.05 32.01h11v7h-11zM24 32.01h11v7H24z\"/></g>",
    "attrs": {
      "viewBox": "0 0 53 56",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});