define("ember-svg-jar/inlined/strategic_planning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{clip-path:url(#Mask_1_Strategic_planning);fill:#e288a4}</style><circle id=\"Circle_2_Strategic_planning\" cx=\"112.5\" cy=\"112.5\" r=\"112.5\" fill=\"#bf647f\"/><g id=\"Graphic_2_Strategic_planning\"><defs><circle id=\"Mask_2_Strategic_planning\" cx=\"112.5\" cy=\"112.5\" r=\"112.5\"/></defs><clipPath id=\"Mask_1_Strategic_planning\"><use xlink:href=\"#Mask_2_Strategic_planning\" overflow=\"visible\"/></clipPath><path id=\"BG\" class=\"st1\" d=\"M225 225H0V0h112.5v112.5z\"/><path id=\"Magnifying_Glass\" d=\"M175.1 149.1c6.3-10.7 9.9-23.2 9.9-36.6 0-40-32.5-72.5-72.5-72.5S40 72.5 40 112.5 72.5 185 112.5 185c13.2 0 25.6-3.5 36.2-9.7l31.1 31.1 26.3-26.3-31-31zm-62.6 3.4c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z\" clip-path=\"url(#Mask_1_Strategic_planning)\" fill=\"#fff\"/><circle id=\"Center\" class=\"st1\" cx=\"112.5\" cy=\"112.5\" r=\"40\"/></g>",
    "attrs": {
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 225 225",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});