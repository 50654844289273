define("ember-svg-jar/inlined/gold-tier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path d=\"M4.3 9.2c.5 2.8 1.1 5.5 1.8 8.3.3 1.5 1.2 2.1 2.7 2.1l.1.1c-.4-1.2-.6-2.5-.6-3.9V6c-.4 0-.8-.1-1.2-.1-2.5 0-3.3.8-2.8 3.3zM33.3 15.8c0 1.3-.2 2.6-.6 3.8h.1c1.5 0 2.4-.6 2.7-2.1.6-2.7 1.2-5.5 1.8-8.3.5-2.5-.3-3.3-2.8-3.2-.4 0-.8 0-1.2.1v7.1zM20.6 28.3h-.3z\"/></g><path d=\"M31.7 37.7c-.8-.1-.9-.4-1-1.1-.1-1.6-.9-2.3-2.4-2.3-.8 0-1.7-.1-2.5-.1H15.5c-.7 0-1.3.1-2 .1-1.9 0-2.8.8-3 2.6-.1.6-.3.7-.8.8-1 .2-1.5.9-1.4 1.9.1 1.1.8 1.4 1.8 1.4h21.4c.8 0 1.5-.1 1.6-1.2 0-1-.1-2-1.4-2.1z\" fill=\"#5a596e\"/><path d=\"M4.3 9.2c.5 2.8 1.1 5.5 1.8 8.3.3 1.5 1.2 2.1 2.7 2.1h.1c-.4-1.2-.6-2.5-.6-3.8V6H7.1c-2.5-.1-3.3.7-2.8 3.2zM33.3 13.1v2.7c0 1.3-.2 2.6-.6 3.8h.1c1.5 0 2.4-.6 2.7-2.1.6-2.7 1.2-5.5 1.8-8.3.5-2.5-.3-3.3-2.8-3.2h-1.2z\" fill=\"none\"/><path d=\"M8.9 19.7c0-.1 0-.1 0 0l-.1-.1c-1.5 0-2.4-.6-2.7-2.1-.6-2.7-1.2-5.5-1.8-8.3-.5-2.5.3-3.3 2.8-3.3h1.2V4.5c0-.6.2-1.3.5-1.8H4.7C1.6 2.7-.2 4.9.4 8c.7 3.6 1.4 7.3 2.1 10.9.5 2.4 2.2 4 4.7 4h3.3c-.7-1-1.2-2.1-1.6-3.2zM36.9 2.6h-4.1c.3.5.5 1.1.5 1.8V6h1.2c2.5 0 3.3.8 2.8 3.2-.5 2.8-1.1 5.5-1.8 8.3-.3 1.5-1.2 2.1-2.7 2.1h-.1c-.4 1.2-.9 2.3-1.6 3.3h3.3c2.5-.1 4.2-1.6 4.7-4 .7-3.6 1.4-7.3 2.1-10.9.6-3.1-1.2-5.4-4.3-5.4zM20.8 28.3h-.5c-1 0-2-.2-3-.5-.1 0-.1 0-.1-.1-.3 2.1-.9 4.7-1.7 6.5h10.3c-.9-1.7-1.4-4.3-1.7-6.4h-.2c-1 .3-2 .5-3.1.5z\" fill=\"#f08349\"/><path d=\"M32.8 2.7C32.1 1.5 30.9.6 29.4.6H12.2c-1.5 0-2.8.8-3.4 2.1-.3.5-.5 1.1-.5 1.8v11.3c0 1.3.2 2.6.6 3.8v.1c.4 1.2.9 2.2 1.6 3.2 1.6 2.3 3.9 4 6.7 4.8.1 0 .1 0 .1.1 1 .3 2 .4 3 .5h.5c1.1 0 2.1-.1 3.1-.4h.2c2.9-.8 5.3-2.6 7-5 .7-1 1.2-2.1 1.6-3.3.4-1.2.6-2.5.6-3.8V4.5c0-.7-.2-1.3-.5-1.8z\" fill=\"#fec829\"/><path d=\"M23 10.7l3.4.5c.3 0 .5.2.6.5s0 .6-.2.8l-2.4 2.4.6 3.4c0 .3-.1.6-.3.7-.2.2-.5.2-.8.1l-3-1.6-3 1.6c-.3.1-.6.1-.8-.1s-.3-.5-.3-.7l.6-3.4-2.4-2.4c-.2-.2-.3-.5-.2-.8s.3-.5.6-.5l3.4-.5 1.5-3.1c.1-.3.4-.4.7-.4s.6.2.7.4z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 41.6 41.6",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});