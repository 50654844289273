define("ember-svg-jar/inlined/nature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<switch><g><path d=\"M47.8 108.8c60.7.2 29.4-75.9 17.8-100.7-.4-.7-3.5-8.2-3.8-8.1-.2-.3-7 4.9-7.7 5.4-22.8 16-86.1 64.5-34.3 96 19.9 12 5.4 7.3 28 7.4z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#7ebc1b\"/><path d=\"M27.9 118l7.5-26.6c-3.2-3.5-5.8-7.5-7.6-11.9-2.4-5.5-4.1-11.3-5.2-17.2l-.9-4.9 2.1 5.3c3.5 8.8 8.2 20.9 12.7 25l5.2-17.4C38.6 66.9 36 63.2 34 59c-2-4.1-3.4-8.4-4.1-12.9l-.8-4.2 1.7 3.9c1.8 4.5 3.9 8.8 6.2 13.1 1.5 2.9 3.4 5.4 5.8 7.6L46.6 54c-2.9-3.3-5.4-7.1-7.2-11.1-1.8-4.1-3.1-8.5-3.7-12.9l-.7-4.5 1.6 4.1c1.7 4.4 3.6 8.8 5.8 13 1.8 3.3 3.6 6 5.4 7.5L57.4 18l1 .3-6.9 27.9c2.3-.4 5.2-1.8 8.4-3.8 4-2.6 7.8-5.4 11.5-8.4l3.5-2.8-2.8 3.4c-2.8 3.5-6 6.7-9.7 9.3-3.6 2.6-7.6 4.6-11.8 6l-3.1 12.7c3.1-.7 6.1-2 8.9-3.7 4.1-2.5 8.1-5.2 11.9-8l3.4-2.5-2.8 3.2c-2.9 3.5-6.3 6.5-10.1 9.1-3.8 2.5-8 4.4-12.3 5.8L42 85c3.6-1.3 6.9-3.1 10-5.3 5-3.3 9.7-7 14.2-11l3-2.8-2.3 3.4c-3.1 4.4-6.9 8.2-11.3 11.4-4.4 3.4-9.4 6.1-14.6 8.1l-7.9 30.5c-.1.2-.1.5-.2.8l-.1.6c-.4 1.6-.9 2.6-3.3 2s-2.5-1.6-2.2-3c.1-.4.2-.8.3-1.1l.3-.6z\" fill=\"#629907\"/></g></switch>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 84 122.9"
    }
  };
  _exports.default = _default;
});