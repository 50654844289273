define("ember-svg-jar/inlined/learner_positioning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<clipPath id=\"a\"><circle cx=\"112.5\" cy=\"112.5\" r=\"82.5\"/></clipPath><circle cx=\"112.5\" cy=\"112.5\" fill=\"#fece56\" r=\"112.5\"/><path clip-path=\"url(#a)\" d=\"M30 120h55v75H30z\" fill=\"#ce7c39\"/><path clip-path=\"url(#a)\" d=\"M85 91.3h58V195H85z\" fill=\"#ef9e22\"/><path clip-path=\"url(#a)\" d=\"M143 34h52v161h-52z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 225 225",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});