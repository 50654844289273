define("ember-svg-jar/inlined/vertical-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.553 15H8.447a.503.503 0 010-1h11.106a.503.503 0 010 1zM19.553 12H8.447a.503.503 0 010-1h11.106a.503.503 0 010 1zM19.553 9H8.447a.503.503 0 010-1h11.106a.503.503 0 010 1zM4.145 16.91L3 18.15V1.85L4.145 3.09a.475.475 0 00.708 0 .575.575 0 000-.766l-2-2.167a.473.473 0 00-.707 0l-2 2.167a.575.575 0 000 .766.473.473 0 00.707 0L2 1.849v16.302L.853 16.909a.474.474 0 00-.707 0 .575.575 0 000 .766l2 2.167a.475.475 0 00.708 0l2-2.167a.575.575 0 000-.766.474.474 0 00-.707 0zM19.553 6H8.447a.503.503 0 010-1h11.106a.503.503 0 010 1z\"/>",
    "attrs": {
      "height": "21",
      "viewBox": "0 0 20 20",
      "width": "21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});