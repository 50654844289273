define("ember-svg-jar/inlined/pillar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.72 2.47h18.71v8.5H4.72z\" fill=\"#f5f7fa\"/><path d=\"M4.72 8.84h19.33v4.17H4.72z\" fill=\"#ccd1d9\"/><path d=\"M23.79 13.27H5.17V8.59h18.62zM5 12.76h18.79V9.09H5z\" fill=\"#ccd1d9\"/><path d=\"M5.72 9.85h.51v3.16h-.51z\" fill=\"#03668b\"/><g fill=\"#abb3be\"><path d=\"M7.15 9.85h.51v3.16h-.51zM8.58 9.85h.51v3.16h-.51zM10.01 8.84h.51v4.17h-.51zM11.44 8.84h.51v4.17h-.51zM12.87 8.84h.51v4.17h-.51zM14.3 8.84h.51v4.17h-.51zM15.73 8.84h.51v4.17h-.51zM17.16 8.84h.51v4.17h-.51zM18.59 8.84h.51v4.17h-.51zM20.02 9.85h.51v3.16h-.51z\"/></g><path d=\"M21.46 9.85h.51v3.16h-.51zM4.72 7.67h19.33v1.17H4.72z\" fill=\"#ccd1d9\"/><path d=\"M24.3 9.09H4.47V7.42H24.3zM5 8.59h18.79v-.67H5z\" fill=\"#abb3be\"/><path d=\"M4.23 9.83a2.33 2.33 0 010-3.29 2.9 2.9 0 014.11 0 3.64 3.64 0 010 5.14 4.55 4.55 0 01-6.43 0 5.69 5.69 0 010-8A6.64 6.64 0 016.25 2H19S5 10.56 4.23 9.83z\" fill=\"#f5f7fa\"/><path d=\"M5.13 13.27a4.78 4.78 0 01-3.39-1.4 5.93 5.93 0 010-8.39 6.9 6.9 0 014.48-1.76H19v.51H6.25a6.31 6.31 0 00-4.15 1.6 5.43 5.43 0 000 7.68 4.3 4.3 0 006.07 0 3.39 3.39 0 000-4.78 2.66 2.66 0 00-3.75 0 2.08 2.08 0 000 2.93l-.37.34a2.58 2.58 0 010-3.65 3.16 3.16 0 014.47 0 3.88 3.88 0 010 5.5 4.78 4.78 0 01-3.39 1.42z\" fill=\"#abb3be\"/><path d=\"M24.44 9.83a2.33 2.33 0 000-3.29 2.91 2.91 0 00-4.11 0 3.64 3.64 0 000 5.14 4.55 4.55 0 006.43 0 5.68 5.68 0 000-8A6.63 6.63 0 0022.43 2h-5.58s6.87 8.56 7.59 7.83z\" fill=\"#f5f7fa\"/><path d=\"M23.54 13.27a4.78 4.78 0 01-3.39-1.4 3.88 3.88 0 010-5.5 3.16 3.16 0 014.47 0 2.58 2.58 0 010 3.65l-.36-.36a2.08 2.08 0 000-2.93 2.65 2.65 0 00-3.75 0 3.38 3.38 0 000 4.78 4.29 4.29 0 006.06 0 5.43 5.43 0 000-7.68 6.33 6.33 0 00-4.18-1.6h-5.54v-.51h5.58a6.91 6.91 0 014.51 1.75 5.93 5.93 0 010 8.39 4.78 4.78 0 01-3.4 1.41z\" fill=\"#abb3be\"/><path d=\"M4.72 13.27v-.25h19.07v.25\" fill=\"#abb3be\"/><path d=\"M6.25.25h16.39v1.72H6.25z\" fill=\"#f5f7fa\"/><path d=\"M22.88 2.23H6V0h16.88zM6.5 1.72h15.88V.51H6.5z\" fill=\"#abb3be\"/>",
    "attrs": {
      "viewBox": "0 0 28.67 13.27",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});