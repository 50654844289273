define("ember-svg-jar/inlined/toolbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"112.5\" cy=\"112.5\" fill=\"#7294b7\" r=\"112.5\"/><path d=\"M107.6 142.2l-30.2 30.2c-6.1 6.1-16 6.1-22.1 0s-6.1-16 0-22.1L92.5 113l13.8 13.8c-1.2 5.2-.6 10.5 1.3 15.4zm65.5 19.2l-12.9 12.9c-3.5 3.6-9.3 3.6-12.9 0l-28.6-28.6c-5.6-5.6-6.7-14.1-3.4-20.8l-26-26H74.2L50.7 67.5l15.6-15.6 31.2 23.4v15.2l26 26c6.8-3.3 15.2-2.2 20.8 3.4l28.6 28.6c3.7 3.6 3.7 9.3.2 12.9zm-100.9-.1c0-3.2-2.6-5.9-5.9-5.9s-5.9 2.6-5.9 5.9c0 3.2 2.6 5.9 5.9 5.9s5.9-2.7 5.9-5.9zm53.5-53.7l-20-20c-.2-9.2 3.1-18.3 10.1-25.3 9-9 21.8-12.1 33.3-9.3 2.2.6 3 3.3 1.4 4.9l-18.2 18.2 2.8 16.6 16.6 2.8 18.2-18.2c1.6-1.6 4.4-.9 4.9 1.3 2.9 11.6-.2 24.3-9.3 33.4-3.2 3.2-6.8 5.5-10.7 7.2l-4.7-4.7c-4.9-4.9-11.4-7.6-18.3-7.6-2.1 0-4.1.2-6.1.7z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 225 225",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});