define("ember-svg-jar/inlined/leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Union 7\" d=\"M75.234 190.649a95.706 95.706 0 01-15.321-4.827l-2.382-.75.234-.16A96.523 96.523 0 010 96.501 96.5 96.5 0 0196.5.001a96.5 96.5 0 0196.408 92.213l.094-.064-.007 3.211c0 .38.007.762.007 1.142 0 .521 0 1.043-.012 1.562l-.271 129.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "193.001",
      "height": "227.66",
      "viewBox": "0 0 193.001 227.66"
    }
  };
  _exports.default = _default;
});