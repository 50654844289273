define("ember-composable-helpers/helpers/slice", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function slice(_ref) {
    let [start, end, array] = _ref;
    if (!array) {
      array = [];
    }
    return array.slice(start, end);
  }
  var _default = (0, _helper.helper)(slice);
  _exports.default = _default;
});