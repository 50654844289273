define("ember-svg-jar/inlined/very-happy-eyes-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M91.061 39.917c0 4.5-.9 9-2.6 13.1-36.4 8.7-74 .9-80.1-.3-1.6-4.1-2.4-8.4-2.3-12.8h.3s43.1 10.5 84.2.3l.5-.3z\" fill=\"#fff\"/><path d=\"M88.561 53.117c-5.2 12.5-18.4 21-39.9 21s-35-8.7-40.2-21.6c6.1 1.7 43.7 9.6 80.1.6z\" fill=\"#ea5a47\"/><path d=\"M7.374 37.574h.3c14.7 3.1 29.7 4.7 44.8 4.7 13 .1 26-1.4 38.7-4.4.9-.5 2-.4 2.8.1.9.5 1.4 1.4 1.4 2.4 0 4.9-1 9.7-2.9 14.3-.4.8-1.1 1.5-2 1.7-12.4 2.9-25.1 4.3-37.8 4.2-14.4 0-28.7-1.5-42.8-4.3-1.2-.2-2.2-.9-2.9-1.9-1.8-4.4-2.7-9.2-2.6-14 .2-1.5 1.4-2.8 3-2.8zm45.2 10.5c-14.1 0-28.2-1.4-42-4.1.2 2.3.7 4.5 1.4 6.7 13.5 2.6 27.2 4 40.9 4 11.7.1 23.5-1.1 34.9-3.6.8-2.2 1.3-4.4 1.6-6.7-12.1 2.6-24.4 3.8-36.8 3.7z\"/><path d=\"M9.774 50.074c.3 0 .5 0 .8.1 4.3 1.2 21.2 4.7 41.9 4.7 12.4.1 24.7-1.3 36.7-4.2 1.6-.4 3.1.6 3.5 2.1.2.6.1 1.2-.1 1.8-6.1 14.7-21.3 22.8-42.6 22.8-21.2 0-36.8-8.5-42.9-23.4-.6-1.5.1-3.2 1.6-3.8.4 0 .8-.1 1.1-.1zm42.8 10.7c-12.5 0-24.9-1.2-37.1-3.5 6.5 9.3 18.5 14.4 34.6 14.4 15.9 0 27.8-4.9 34.2-13.8-10.5 1.9-21.1 2.9-31.7 2.9zM71.4 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8z\"/><path class=\"svg-face-mouth\" d=\"M25 2.3c4.9 0 8.8 3.9 8.8 8.8s-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8c0-4.8 3.9-8.8 8.8-8.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 80"
    }
  };
  _exports.default = _default;
});