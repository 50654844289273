define("ember-intercom-io/templates/components/intercom-io", ["exports", "ember-cli-htmlbars"], function (_exports, _emberCliHtmlbars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _emberCliHtmlbars.hbs)('{{yield}}\n', {
    moduleName: 'ember-intercom-io/templates/components/intercom-io.hbs'
  });
  _exports.default = _default;
});